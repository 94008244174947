function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { FavouritesRepository } from '../apps/favourites/repositories/FavouritesRepository';
export var useFavouritesStore = defineStore('favourites', () => {
  var langCode = useRoute().params.lang || 'en-gb';
  var favourites = ref({});
  var wishlists = ref({});
  var favouritesPrimaryPhoto = ref(null);
  var authStore = useAuthStore();
  var currentlyLoggedIn = false;
  onBeforeMount(() => {
    initFavourites();
    authStore.$subscribe((_, state) => {
      if (currentlyLoggedIn && !state.isLoggedIn) {
        clearAllFavourites();
      }
      if (!currentlyLoggedIn && state.isLoggedIn) {
        initFavourites();
      }
      if (currentlyLoggedIn !== state.isLoggedIn) {
        currentlyLoggedIn = state.isLoggedIn;
      }
    }, {
      detached: true
    });
  });
  function initFavourites() {
    // TODO ideally use Promise.all to get all the data at once
    void getAllFavourites();
    void getAllWishlists();
    void getAllGuestWishlists();
  }
  var count = computed(() => {
    return Object.keys(favourites.value).reduce((acc, key) => 'added' in favourites.value[key] ? acc + 1 : acc, 0);
  });
  var defaultFavourites = computed(() => {
    return Object.values(favourites.value).filter(favourite => 'added' in favourite);
  });
  var totalCount = computed(() => {
    return Object.keys(favourites.value).length;
  });
  var isDefaultFavourite = computed(() => campsiteId => {
    var favourite = favourites.value[campsiteId] || null;
    return !!favourite && !!favourite.added;
  });
  var isSaved = computed(() => campsiteId => {
    return !!favourites.value[campsiteId];
  });
  function getFavouriteInfo(campsiteId) {
    return favourites.value[campsiteId];
  }
  function bulkAdd(bulkFavourites) {
    var favouritesCopy = _objectSpread({}, favourites.value);
    for (var campsiteId in bulkFavourites) {
      var _favouritesCopy$camps, _bulkFavourites$camps;
      favouritesCopy[campsiteId] = _objectSpread(_objectSpread(_objectSpread({}, favouritesCopy[campsiteId]), bulkFavourites[campsiteId]), {}, {
        wishlists: _objectSpread(_objectSpread({}, (_favouritesCopy$camps = favouritesCopy[campsiteId]) === null || _favouritesCopy$camps === void 0 ? void 0 : _favouritesCopy$camps.wishlists), (_bulkFavourites$camps = bulkFavourites[campsiteId]) === null || _bulkFavourites$camps === void 0 ? void 0 : _bulkFavourites$camps.wishlists)
      });
    }
    favourites.value = favouritesCopy;
  }
  function updateFavouritesPrimaryPhoto(photo) {
    favouritesPrimaryPhoto.value = photo;
  }
  function updateFavouriteInWishlist(listId, campsiteId, note) {
    var favourite = favourites.value[campsiteId];
    if (favourite && favourite.wishlists) {
      var _updatedWishlists$lis;
      var updatedWishlists = _objectSpread({}, favourite.wishlists);
      updatedWishlists[listId] = _objectSpread(_objectSpread({}, updatedWishlists[listId]), {}, {
        notes: note,
        created: ((_updatedWishlists$lis = updatedWishlists[listId]) === null || _updatedWishlists$lis === void 0 ? void 0 : _updatedWishlists$lis.created) || new Date().toISOString()
      });
      favourites.value = _objectSpread(_objectSpread({}, favourites.value), {}, {
        [campsiteId]: _objectSpread(_objectSpread({}, favourite), {}, {
          wishlists: updatedWishlists
        })
      });
    }
  }
  function addFavouriteToWishlist(listId, campsiteId, note) {
    var favCopy = _objectSpread({}, favourites.value);
    if (!favCopy[campsiteId]) {
      favCopy[campsiteId] = {
        wishlists: {}
      };
    }
    if (!favCopy[campsiteId].wishlists) {
      favCopy[campsiteId].wishlists = {};
    }
    favCopy[campsiteId].wishlists[listId] = {
      id: listId,
      notes: note,
      created: new Date().toISOString()
    };
    favourites.value = favCopy;
  }
  function removeFavouriteFromWishlist(listId, campsiteId) {
    var favCopy = _objectSpread({}, favourites.value);
    var favourite = favCopy[campsiteId];
    if (favourite && favourite.wishlists && favourite.wishlists[listId]) {
      var updatedWishlists = _objectSpread({}, favourite.wishlists);
      delete updatedWishlists[listId];
      if (Object.keys(updatedWishlists).length === 0) {
        delete favCopy[campsiteId].wishlists;
      } else {
        favCopy[campsiteId] = _objectSpread(_objectSpread({}, favourite), {}, {
          wishlists: updatedWishlists
        });
      }
      if (wishlists.value[listId]) {
        wishlists.value[listId].itemCount--;
      }
    }
    if (Object.keys(favCopy[campsiteId]).length === 0) {
      delete favCopy[campsiteId];
    }
    favourites.value = favCopy;
  }
  function addNewWishlist(listId, name) {
    var status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'private';
    var isOwner = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var itemCount = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var primaryPhoto = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
    var updatedWishlists = _objectSpread({}, wishlists.value);
    updatedWishlists[listId] = {
      id: listId,
      name,
      status,
      isOwner,
      itemCount,
      primaryPhoto
    };
    wishlists.value = updatedWishlists;
  }
  function removeWishlist(wishlistId) {
    delete wishlists.value[wishlistId];
  }
  function bulkAddWishlists(bulkWishlist) {
    wishlists.value = _objectSpread(_objectSpread({}, wishlists.value), bulkWishlist);
  }
  function addFavourite(favouriteId, date) {
    var updatedFavourites = _objectSpread({}, favourites.value);
    if (updatedFavourites[favouriteId]) {
      updatedFavourites[favouriteId].added = date;
    } else {
      updatedFavourites[favouriteId] = {
        added: date
      };
    }
    favourites.value = updatedFavourites;
  }
  function removeFavourite(favouriteId) {
    var updatedFavourites = _objectSpread({}, favourites.value);
    if (updatedFavourites[favouriteId]) {
      delete updatedFavourites[favouriteId].added;
      if (Object.keys(updatedFavourites[favouriteId]).length === 0) {
        delete updatedFavourites[favouriteId];
      }
    }
    favourites.value = updatedFavourites;
  }
  function clearAllFavourites() {
    favourites.value = {};
    wishlists.value = {};
    favouritesPrimaryPhoto.value = null;
  }
  function updateWishlistPrimaryPhoto(wishlistId, primaryPhoto) {
    wishlists.value[wishlistId] = _objectSpread(_objectSpread({}, wishlists.value[wishlistId]), {}, {
      primaryPhoto
    });
  }
  function updateWishlistData(data) {
    wishlists.value[data.id] = _objectSpread(_objectSpread({}, wishlists.value[data.id]), data);
  }
  function getAllFavourites() {
    return _getAllFavourites.apply(this, arguments);
  }
  function _getAllFavourites() {
    _getAllFavourites = _asyncToGenerator(function* () {
      var repo = new FavouritesRepository(langCode);
      var {
        favouriteDatedWithLists,
        favouritesPrimaryPhoto
      } = yield repo.getAll();
      bulkAdd(favouriteDatedWithLists);
      updateFavouritesPrimaryPhoto(favouritesPrimaryPhoto);
    });
    return _getAllFavourites.apply(this, arguments);
  }
  function getAllWishlists() {
    return _getAllWishlists.apply(this, arguments);
  }
  function _getAllWishlists() {
    _getAllWishlists = _asyncToGenerator(function* () {
      var repo = new FavouritesRepository(langCode);
      var response = yield repo.getAllWishlists();
      bulkAddWishlists(response);
    });
    return _getAllWishlists.apply(this, arguments);
  }
  function getAllGuestWishlists() {
    return _getAllGuestWishlists.apply(this, arguments);
  }
  function _getAllGuestWishlists() {
    _getAllGuestWishlists = _asyncToGenerator(function* () {
      if (!process.server) {
        var _wishlists = localStorage.getItem('guestWishlists');
        var repo = new FavouritesRepository(langCode);
        var wishlistArray = _wishlists ? JSON.parse(_wishlists) : [];
        for (var wishlist of wishlistArray) {
          var wishlistDetails = yield repo.getWishlistDetails(wishlist);
          addNewWishlist(wishlistDetails.id, wishlistDetails.name, wishlistDetails.status, wishlistDetails.isOwner, wishlistDetails.itemCount, wishlistDetails.primaryPhoto);
          for (var campsite in wishlistDetails.items) {
            addFavouriteToWishlist(wishlistDetails.id, campsite, wishlistDetails.items[campsite].notes);
          }
        }
      }
    });
    return _getAllGuestWishlists.apply(this, arguments);
  }
  return {
    addFavourite,
    addFavouriteToWishlist,
    addNewWishlist,
    bulkAdd,
    bulkAddWishlists,
    clearAllFavourites,
    count,
    defaultFavourites,
    favourites,
    favouritesPrimaryPhoto,
    getFavouriteInfo,
    isDefaultFavourite,
    isSaved,
    removeFavourite,
    removeFavouriteFromWishlist,
    removeWishlist,
    totalCount,
    updateFavouriteInWishlist,
    updateFavouritesPrimaryPhoto,
    updateWishlistData,
    updateWishlistPrimaryPhoto,
    wishlists
  };
});